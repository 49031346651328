.selected-revision {
  border-left: 4px solid #167df0; }

.root {
  position: relative; }

.ambient {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  object-fit: fill;
  opacity: 0;
  width: 100%;
  height: 100vh;
  filter: grayscale(100%); }
  .ambient.active {
    opacity: 0.5; }
